import React, { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  FlexRow,
  H1,
  H6,
  PrismicImage,
  PrismicLink,
  RichText,
  SectionWrapper,
  SplitHeader,
  Text
} from "src/components";

function Annoucement({
  title,
  excerpt,
  description,
  image,
  author,
  authorImage,
  date,
  buttonLink,
  buttonText,
  setInvert,
  setInvertLogo
}) {
  const [link] = useState(() => {
    if (typeof window !== "undefined") {
      return window.location.href;
    } else {
      return null;
    }
  });
  return (
    <>
      <SplitHeader
        left={
          <>
            {image && (
              <Box position="relative" bg="bg.reverse">
                <PrismicImage image={image} alt={title && title} />
              </Box>
            )}
          </>
        }
        right={
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              height: 100%;
              ${props => props.theme.mediaQueries.large} {
                align-items: flex-start;
                text-align: left;
              }
            `}
          >
            <H6 children={`Announcement`} color="text.reverse" />
            {title && <H1 children={title} mt={4} color="text.reverse" />}
            {excerpt && (
              <Text fontSize={2} mt={[3, 5]} color="text.reverse">
                {excerpt}
              </Text>
            )}
            {author && (
              <FlexRow justifyContent="flex-start" alignItems="center" mt={3}>
                {authorImage && authorImage.url && (
                  <Avatar image={authorImage} />
                )}
                <Box flex={1} ml={authorImage && authorImage.url && 2}>
                  <Text fontSize={1} color="text.reverse">
                    {author && author}
                    {date && <span> · {date}</span>}
                  </Text>
                </Box>
              </FlexRow>
            )}
            {buttonLink && buttonText && (
              <Box mt={[3, 5]}>
                <Button
                  children={buttonText}
                  as={PrismicLink}
                  link={buttonLink}
                  variant="reverseAlt"
                />
              </Box>
            )}
          </div>
        }
        color="brand.altPrimary"
        invert={true}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
      <SectionWrapper maxWidth={0} py={[6, 8]} bg="bg.default">
        {description && (
          <RichText fontSize={[1, 2]} color="text.body">
            {description}
          </RichText>
        )}
        {buttonLink && buttonText && (
          <Box mt={5}>
            <Button
              children={buttonText}
              as={PrismicLink}
              link={buttonLink}
              variant="primary"
            />
          </Box>
        )}
      </SectionWrapper>
    </>
  );
}

export default Annoucement;
