import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import moment from "moment";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO } from "src/components";

import Announcement from "./_components/_announcement";

function AnnoucementPage({ data, setInvert, setInvertLogo, preview }) {
  let announcement = {};
  if (data) {
    if (data.announcement) {
      if (data.announcement.dataString) {
        announcement = JSON.parse(data.announcement.dataString);
        data.authors.edges.forEach(author => {
          if (announcement.author) {
            if (author.node.prismicId === announcement.author.id) {
              announcement.authorData = JSON.parse(author.node.dataString);
            }
          }
        });
      }
    }
  } else {
    if (preview) {
      announcement = preview;
    }
  }
  return (
    <>
      <SEO
        title={announcement.title && RichText.asText(announcement.title)}
        image={
          announcement.image && announcement.image.url && announcement.image.url
        }
        imageAlt={announcement.image.alt && announcement.image.alt}
      />
      <Announcement
        title={
          announcement.title &&
          announcement.title[0] &&
          RichText.asText(announcement.title)
        }
        excerpt={announcement.excerpt}
        description={
          announcement.body &&
          RichText.render(announcement.body, linkResolver, serializer)
        }
        image={
          announcement.image && announcement.image.url && announcement.image
        }
        authorImage={announcement.authorData && announcement.authorData.picture}
        author={
          announcement.authorData &&
          announcement.authorData.name &&
          RichText.asText(announcement.authorData.name)
        }
        date={
          announcement.date && moment(announcement.date).format("MMM D, YYYY")
        }
        buttonLink={announcement.cta_button_link}
        buttonText={announcement.cta_button_text}
        setInvert={setInvert}
        setInvertLogo={setInvertLogo}
      />
    </>
  );
}

export default AnnoucementPage;

export const query = graphql`
  query($id: String!) {
    announcement: prismicAnnouncement(id: { eq: $id }) {
      prismicId
      dataString
    }
    authors: allPrismicStaffMember {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
